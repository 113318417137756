import React, { useEffect, useState } from 'react'
import { api_url, bnbFee, receivingAddress, TGWContractAddress, titleDescription } from '../../config/Config';
import uploadIcon from '../../Assets/Image/uploadIcon.png';
import _fetch from '../../config/api';
import toasted from '../../config/toast';
import { useTranslation } from 'react-i18next';
import Loader from '../../config/Loader';
import { Bookmark, FullscreenSharp } from '@mui/icons-material';
import Modal from 'react-responsive-modal';
import SupportTab from '../../Private/SupportTab';
import { useWeb3Con } from '../../config/Web3ContextProvider';
import Web3Set from '../../config/Web3Set';

const FailedUpgrade = (props) => {
     const [web3Details, setweb3Details] = useState({ web3: null, address: '' })

     const { t } = useTranslation();
     const { WalletAddress, web3data } = useWeb3Con();
     const [tokenContract, settokenContract] = useState();
     const [web3, setweb3] = useState();
     const [TGWContract, setTGWContract] = useState();
     const [activeLogin, setactiveLogin] = useState(false);

     const [approveLoader, setapproveLoader] = useState('0');
     const [hash, sethash] = useState('');
     useEffect(() => {
          document.title = `${props.pageTitle} | ${titleDescription}`;
          getCSRF()
     }, []);
     const [csrfToken, setCsrfToken] = useState('');

     const getCSRF = async () => {
          await fetch(`${api_url}security/csrf-token`, { credentials: 'include' }) // Ensure cookies are sent
               .then(response => response.json())
               .then(data => setCsrfToken(data.csrfToken));

     }

     const [amount, setamount] = useState();

     const [btnDisable, setbtnDisable] = useState(false);
     const [loading, setLoading] = useState(false);

     const SubmitForm = async (e) => {
          setLoading(true);
          setapproveLoader(0);
          e.preventDefault()


          let data = {
               "amount": amount,
               "wallet_address": WalletAddress,
               "hash": hash,

          }


          let res = await _fetch(`${api_url}activation/activateNow`, "newPost", data, csrfToken)
          if (res?.status === "success") {
               toasted.success(res?.message);
               setLoading(false);

          } else {
               setLoading(false);
               toasted.error(res?.message);
               getCSRF()
          }
     }


     const [usdtBalance, setusdtBalance] = useState(0);



     return (
          <main>


               <div className="support_main">
                    <div className="support_inner mb-4">
                         <div className='card-header'>
                              <p>Failed Upgrade Buying Package</p>
                              <h5>Your Balane is: {usdtBalance?.toFixed(4)} USDT</h5>
                         </div>
                         {/* <h3 className='text-danger'>Important Notice !!!</h3>
                         <p className='mb-0'>New Registration/Upgrade and Withdrawal will be off  From 12:00 PM To 3:00 PM </p>
                         <p>The inconvenience caused is deeply regretted.</p> */}


                         <Web3Set stateprop={setweb3Details} setusdtBalance={setusdtBalance} setweb3={setweb3} setTGWContract={setTGWContract} settokenContract={settokenContract} />


                         <div className="support-section">
                              <form onSubmit={SubmitForm}>

                                   <div className="select_reason mb-3">
                                        <label className='form_lable' htmlFor=''> Wallet Address</label>
                                        <input type="text" placeholder='Enter your wallet address' value={WalletAddress} readOnly />
                                   </div>
                                   <div className="select_reason mb-3">
                                        <label className='form_lable' htmlFor=''> Amount</label>
                                        <input type="number" placeholder='Enter amount' value={amount} onChange={(e) => { setamount(e.target.value) }} />
                                   </div>

                                   <div className="select_reason mb-3">
                                        <label className='form_lable' htmlFor=''> Hash</label>
                                        <input type="text" placeholder='Enter txn hash' value={hash} onChange={(e) => { sethash(e.target.value) }} />
                                   </div>

                                   <div className="btn-sec">
                                        <button className='new_button_css_dashboard' type='submit' disabled={loading}>


                                             {approveLoader == 0 ? 'Upgrade Now' : approveLoader == 1 ? <Loader LoaderTxt={' Waiting for approval!'} /> : <Loader LoaderTxt={' Waiting for confirmation'} />}
                                        </button>



                                   </div>
                              </form>


                         </div>
                    </div>
               </div>



          </main>
     )
}

export default FailedUpgrade