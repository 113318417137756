import React, { useEffect, useState } from "react";
import { api_url, bseScanLink, titleDescription } from "../../config/Config";
import { Table } from "react-bootstrap";
import _fetch from "../../config/api";
import dateformat from "dateformat";
import ReactPaginate from "react-paginate";
import { useTranslation } from 'react-i18next';
import NoResult from "./NoResult";
import SupportTab from "../../Private/SupportTab";

const PayHistory = (props: any) => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = `${props.pageTitle} | ${titleDescription}`;
  });

  const [withdrawHistory, setWithdrawHistory] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setpage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    getWithdrawHistory();
  }, [page, limit]);

  const getWithdrawHistory = async () => {
    setLoading(true);
    let res = await _fetch(`${api_url}withdraw/withdraw-history?type=withdraw_request&limit=${limit}&page=${page}`, "GET", {})
    if (res?.status === "success") {
      setLoading(false);

      setWithdrawHistory(res?.data?.data);
      setTotalRecords(res?.total);
    }
  }

  const handlePageClick = async (event: any) => {
    const newOffset = (event.selected + 1);
    setpage(newOffset);
  };

  const links = [
    { name: t("Payout Request"), path: '/payout-request' },
    { name: t("Payout history"), path: '/pay-history' }
  ];

  return (
    <main>

      <SupportTab links={links} />


      <div className="uni-level-outer">
        <div className="level_inner">
          <div className="table_section">
            <div className="scrolling_table">
              <div className="scroll_under">
                <div className="table_responsive">
                  <Table className="support_table all_tables">
                    <thead className='thead_dashboard'>
                      <tr className="table_heading_row">
                        <th>{t("Release at")}</th>

                        <th>{t("Payout Request")}</th>

                        <th>{t("Amount")}</th>

                        <th>{t("Deduction")}</th>
                        <th>{t("Payable Amount")}</th>
                        <th>{t("Token")}</th>
                        <th>{t("Token Price")}</th>

                        <th>{t("Status")}</th>
                        <th>{t("Check Txn")}</th>


                      </tr>
                    </thead>
                    <tbody className='tbody_dashboard'>
                      {withdrawHistory?.length == 0 ? <tr className='no_data_tr'>
                        <td colSpan={9}>
                          <NoResult />
                        </td>
                      </tr> :
                        <>
                          {withdrawHistory?.map((item: any, index: any) => {
                            return (
                              <>
                                <tr className="table_data_row">
                                  <td className="table_data"> {item?.status != 'Pending' ? dateformat(item?.updated_at, "UTC:dd-mm-yyyy hh:mm:ss TT") : ''}</td>

                                  <td className="table_data"> {dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</td>

                                  <td className="table_data">
                                    <div className="pic_text">${item?.amount?.toFixed(4)}</div>
                                  </td>



                                  <td className="table_data">${item?.deduction?.toFixed(4)}</td>
                                  <td className="table_data payment_fee">${item?.payable_amount?.toFixed(4)}</td>
                                  <td className="table_data">
                                    <div className="pic_text">{item?.coin?.toFixed(4)} TGW</div>
                                  </td>
                                  <td className="table_data payment_fee">${item?.token_price}</td>

                                  <td className="table_data"><span className={item?.status == 'Approve' ? 'text-success' : item?.status == 'Reject' ? 'text-danger' : 'text-info'}> {item?.status}</span> </td>
                                  <td className="table_data"> {item?.hash ? <a href={bseScanLink + item?.hash} target="_blank" className="btn btn-info btn-sm text-white" >View Hash</a> : ''}  </td>


                                </tr>
                              </>
                            )
                          })}
                        </>
                      }
                    </tbody>
                  </Table>
                  {totalRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    pageCount={totalRecords / 10}
                    previousLabel="<"
                  />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default PayHistory;